import Vue, { VNode } from "vue"

import type { Theme } from "../types"

import { MODULE_NAME as THEME_MODULE_NAME } from "../store"
import ThemeModuleGetterTypes from "../store/getter-types"

export default Vue.extend({
  name: "ThemeProvider",
  props: {
    tag: {
      type: String
    }
  },
  computed: {
    themes(): Theme[] {
      const value =
        this.$store.getters[
          `${THEME_MODULE_NAME}/${ThemeModuleGetterTypes.NORMALIZED_THEMES}`
        ]

      if (this.tag) {
        return value.filter(theme => theme.tag === this.tag)
      }

      return value
    }
  },
  render(): VNode {
    return this.$scopedSlots.default({
      themes: this.themes
    })
  }
})
