
import Vue, { PropType } from "vue"
import { mergeData } from "vue-functional-data-merge"
import { BTooltip } from "bootstrap-vue"
import shortid from "shortid"

import { RtbSelect, RtbInputButton, RtbInlineHelp } from "@/components/ui"
import SvgIcon from "@/components/base/SvgIcon.vue"

import { executeListener } from "@/components/ui/helpers"

import type { Theme } from "../types"

export default Vue.extend({
  name: "ThemeSelect",
  functional: true,
  props: {
    value: {
      type: String
    },
    options: {
      type: Array as PropType<Theme[]>,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    uid: {
      type: String,
      default() {
        return shortid.generate()
      }
    }
  },
  render(h, { props, data, children, listeners }) {
    const removeButtonID = `${props.uid}-remove`
    const editButtonID = `${props.uid}-edit`

    return h(
      RtbSelect,
      mergeData(data, {
        props: { options: props.options, label: "Theme", optionText: "name" },
        attrs: { value: props.value }
      }),
      [
        children,
        props.value
          ? h("template", { slot: "append" }, [
              h(
                RtbInputButton,
                {
                  attrs: { id: removeButtonID },
                  props: { color: "danger", variant: "icon", title: "Remove" },
                  on: { click: () => executeListener(listeners.remove) }
                },
                [h(SvgIcon, { props: { name: "trash-regular" } })]
              ),
              h(BTooltip, { props: { target: removeButtonID } }, "Remove"),
              props.editable
                ? h(
                    RtbInputButton,
                    {
                      attrs: { id: editButtonID },
                      props: {
                        color: "secondary",
                        variant: "icon",
                        title: "Edit"
                      },
                      on: { click: () => executeListener(listeners.edit) }
                    },
                    [h(SvgIcon, { props: { name: "pen-regular" } })]
                  )
                : null,
              props.editable
                ? h(BTooltip, { props: { target: editButtonID } }, "Edit")
                : null
            ])
          : null,
        h(RtbInlineHelp, { slot: "help" }, "Placeholder")
      ]
    )
  }
})
