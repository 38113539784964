
import Vue, { PropType } from "vue"
import { mergeData } from "vue-functional-data-merge"

import { RtbSelect, RtbInlineHelp, RtbChip } from "@/components/ui"

import { TAGS } from "../constants"

type Option = { value: string | undefined; label: string; color?: string }

export default Vue.extend({
  name: "TagSelect",
  functional: true,
  props: {
    options: {
      type: Array as PropType<Option[]>,
      default() {
        return [{ value: undefined, label: "All" }, ...TAGS]
      }
    }
  },
  render(h, { props, data, children }) {
    return h(
      RtbSelect,
      mergeData(data, {
        props: {
          options: props.options,
          label: "Tag",
          identity: "value",
          optionText: "label"
        },
        scopedSlots: {
          option: (option: Option) => {
            return option.color
              ? h(
                  RtbChip,
                  { style: { backgroundColor: option.color } },
                  option.label
                )
              : option.label
          },
          "selected-option": ({ item }: { item: Option }) => {
            return item.color
              ? h(
                  RtbChip,
                  { style: { backgroundColor: item.color } },
                  item.label
                )
              : item.label
          }
        }
      }),
      [children, h(RtbInlineHelp, { slot: "help" }, "Placehodler")]
    )
  }
})
