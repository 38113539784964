var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h1", { staticClass: "sr-only" }, [_vm._v("Organizations")]),
      _c(
        "rtb-card",
        [
          _c("rtb-card-body", [
            _c(
              "div",
              { staticClass: "rtb-d-flex align-center" },
              [
                _c(
                  "rtb-button",
                  {
                    staticClass: "mr-2",
                    attrs: { color: "success" },
                    on: { click: _vm.initOrgCreation },
                  },
                  [
                    _c("svg-icon", { attrs: { name: "plus-regular" } }),
                    _vm._v("Add new Org"),
                  ],
                  1
                ),
                _c("rtb-text-input", {
                  staticClass: "w-64 mr-2",
                  attrs: {
                    label: "Search",
                    placeholder: "Search",
                    "hide-label": "",
                  },
                  model: {
                    value: _vm.query,
                    callback: function ($$v) {
                      _vm.query = $$v
                    },
                    expression: "query",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("rtb-table"),
      _c("v-data-table", {
        staticClass: "rtb-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.allOrgs,
          pagination: _vm.pagination,
          search: _vm.query,
          "item-key": "theKey",
        },
        on: {
          "update:pagination": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("tr", [
                  _c(
                    "td",
                    [
                      _c(
                        "rtb-link",
                        {
                          attrs: { href: "#", role: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.pushToGames(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ],
                    1
                  ),
                  _c("td", [_vm._v(_vm._s(item.description))]),
                  _c(
                    "td",
                    { attrs: { width: "108px" } },
                    [
                      _c(
                        "rtb-button",
                        {
                          staticClass: "mr-1",
                          attrs: { variant: "icon" },
                          on: {
                            click: function ($event) {
                              return _vm.edit(item)
                            },
                          },
                        },
                        [_c("svg-icon", { attrs: { name: "pen-regular" } })],
                        1
                      ),
                      _c(
                        "rtb-button",
                        {
                          attrs: { color: "danger", variant: "icon" },
                          on: {
                            click: function ($event) {
                              return _vm.openRemoveDialog(item)
                            },
                          },
                        },
                        [_c("svg-icon", { attrs: { name: "trash-regular" } })],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.deleting,
            callback: function ($$v) {
              _vm.deleting = $$v
            },
            expression: "deleting",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "highlight", dark: "" } },
                [_c("v-toolbar-title", [_vm._v(" Delete Game")])],
                1
              ),
              _c("v-card-text", [
                _vm._v("Are you sure want to delete the org called "),
                _c("b", [_vm._v(_vm._s(_vm.org.name))]),
                _vm._v("?"),
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "error",
                          attrs: { flat: "" },
                          on: { click: _vm.remove },
                        },
                        [_vm._v("Remove")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.deleting = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "no-click-animation": "", width: "768px" },
          model: {
            value: _vm.editing,
            callback: function ($$v) {
              _vm.editing = $$v
            },
            expression: "editing",
          },
        },
        [
          _vm.org
            ? _c(
                "rtb-card",
                [
                  _c(
                    "rtb-tabs",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c(
                        "rtb-tab",
                        { key: "general", attrs: { title: "General" } },
                        [
                          _c(
                            "rtb-card-body",
                            [
                              _c(
                                "rtb-row",
                                [
                                  _c(
                                    "rtb-col",
                                    { attrs: { xs: "4" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: {
                                          label: "Name",
                                          rules: _vm.getRules().OrgName,
                                        },
                                        model: {
                                          value: _vm.org.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.org, "name", $$v)
                                          },
                                          expression: "org.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "rtb-col",
                                    { attrs: { xs: "4" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: {
                                          label: "Description",
                                          rules: _vm.getRules().OrgDescription,
                                        },
                                        model: {
                                          value: _vm.org.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.org,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "org.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "rtb-col",
                                    { attrs: { xs: "4" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: {
                                          label: "Twilio Key",
                                          rules: _vm.getRules().OrgTwilioKey,
                                        },
                                        model: {
                                          value: _vm.org.twilioKey,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.org, "twilioKey", $$v)
                                          },
                                          expression: "org.twilioKey",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "rtb-col",
                                    { attrs: { xs: "4" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: {
                                          label: "Twilio SID",
                                          rules: _vm.getRules().OrgTwilioSID,
                                        },
                                        model: {
                                          value: _vm.org.twilioSid,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.org, "twilioSid", $$v)
                                          },
                                          expression: "org.twilioSid",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "rtb-col",
                                    { attrs: { xs: "4" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: {
                                          label: "Twilio Secret",
                                          rules: _vm.getRules().OrgTwilioSecret,
                                        },
                                        model: {
                                          value: _vm.org.twilioSecret,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.org,
                                              "twilioSecret",
                                              $$v
                                            )
                                          },
                                          expression: "org.twilioSecret",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "rtb-col",
                                    { attrs: { xs: "4" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: {
                                          label: "Twilio Auth Token",
                                          rules:
                                            _vm.getRules().OrgTwilioAuthToken,
                                        },
                                        model: {
                                          value: _vm.org.twilioAuthToken,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.org,
                                              "twilioAuthToken",
                                              $$v
                                            )
                                          },
                                          expression: "org.twilioAuthToken",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "rtb-col",
                                    { attrs: { xs: "4" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: {
                                          label: "Twilio Chat Service ID",
                                          rules:
                                            _vm.getRules()
                                              .OrgTwilioChatServiceId,
                                        },
                                        model: {
                                          value: _vm.org.twilioChatServiceId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.org,
                                              "twilioChatServiceId",
                                              $$v
                                            )
                                          },
                                          expression: "org.twilioChatServiceId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("ThemesProvider", {
                                    attrs: { tag: _vm.themesTag },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var themes = ref.themes
                                            return [
                                              themes.length > 0
                                                ? _c(
                                                    "rtb-col",
                                                    { attrs: { xs: "6" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "rtb-d-flex",
                                                        },
                                                        [
                                                          _c("TagSelect", {
                                                            staticClass:
                                                              "w-28 flex-shrink-0 mr-2",
                                                            model: {
                                                              value:
                                                                _vm.themesTag,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.themesTag =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "themesTag",
                                                            },
                                                          }),
                                                          _c("ThemeSelect", {
                                                            staticClass:
                                                              "flex-grow",
                                                            attrs: {
                                                              options: themes,
                                                              editable: false,
                                                            },
                                                            on: {
                                                              remove: function (
                                                                $event
                                                              ) {
                                                                _vm.org.themeID =
                                                                  null
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.org.themeID,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.org,
                                                                    "themeID",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "org.themeID",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2971688821
                                    ),
                                  }),
                                  _c(
                                    "rtb-col",
                                    { attrs: { xs: "4" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: { label: "Later Comer Video" },
                                        model: {
                                          value: _vm.org.latecomerVideoCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.org,
                                              "latecomerVideoCode",
                                              $$v
                                            )
                                          },
                                          expression: "org.latecomerVideoCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "rtb-tab",
                        { key: "twitch", attrs: { title: "Twitch" } },
                        [
                          _c(
                            "rtb-card-body",
                            [
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mb-3",
                                  attrs: { color: "success" },
                                  on: { click: _vm.addTwitchAccount },
                                },
                                [_vm._v("Add Account")]
                              ),
                              _vm._l(
                                _vm.twitchAccounts,
                                function (twitchAccount, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "rtb-d-flex" },
                                    [
                                      _c(
                                        "rtb-row",
                                        { staticClass: "flex-grow" },
                                        [
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "4" } },
                                            [
                                              _c("rtb-text-input", {
                                                attrs: {
                                                  label: "Twitch Name",
                                                  rules:
                                                    _vm.getRules()
                                                      .TwitchAccountName,
                                                },
                                                model: {
                                                  value:
                                                    _vm.twitchAccounts[index]
                                                      .name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.twitchAccounts[index],
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "twitchAccounts[index].name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "4" } },
                                            [
                                              _c("rtb-text-input", {
                                                attrs: {
                                                  label: "Twitch URL",
                                                  rules:
                                                    _vm.getRules()
                                                      .TwitchAccountURL,
                                                },
                                                model: {
                                                  value:
                                                    _vm.twitchAccounts[index]
                                                      .url,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.twitchAccounts[index],
                                                      "url",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "twitchAccounts[index].url",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "rtb-col",
                                            { attrs: { xs: "4" } },
                                            [
                                              _c("rtb-text-input", {
                                                attrs: {
                                                  label: "Twitch API Key",
                                                  rules:
                                                    _vm.getRules()
                                                      .TwitchAccountAPIKey,
                                                },
                                                model: {
                                                  value:
                                                    _vm.twitchAccounts[index]
                                                      .apiKey,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.twitchAccounts[index],
                                                      "apiKey",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "twitchAccounts[index].apiKey",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "rtb-button",
                                        {
                                          staticClass:
                                            "flex-shrink-0 mt-4 ml-2",
                                          attrs: {
                                            color: "danger",
                                            variant: "icon",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteTwitchAccount(
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: { name: "trash-regular" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "rtb-card-actions",
                    [
                      _vm.loading
                        ? _c("rtb-spinner", { staticClass: "mr-2" })
                        : _vm._e(),
                      _c(
                        "rtb-button",
                        {
                          attrs: { color: "dark", disabled: _vm.loading },
                          on: {
                            click: function ($event) {
                              _vm.editing = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "rtb-button",
                        {
                          attrs: { disabled: _vm.loading },
                          on: { click: _vm.update },
                        },
                        [_vm._v("Save")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }