






















































































































































































































// TODO(Andrew): remove when time comes
// @ts-nocheck
import Vue from "vue"
import { db } from "@/firebase"

import { DEFAULT_LATERCOMER_YOUTUBE_CODE } from "@/config"

import ThemesProvider from "@/modules/theme/providers/themes.provider"

import ThemeSelect from "@/modules/theme/components/ThemeSelect.vue"
import TagSelect from "@/modules/theme/components/TagSelect.vue"

import { fetchOrgs } from "@/services/game.service"

import {
  RtbRow,
  RtbCol,
  RtbButton,
  RtbInputButton,
  RtbLink,
  RtbCard,
  RtbCardBody,
  RtbCardActions,
  RtbTextInput,
  RtbSelect,
  RtbTabs,
  RtbTab,
  RtbTable,
  RtbSpinner
} from "@/components/ui"

import rules from "@/config/rules"

enum Tab {
  General,
  Twitch
}

const orgEntity = {
  name: "",
  description: "",
  twilioRegion: "",
  twilioKey: "",
  twilioSid: "",
  twilioSecret: "",
  twilioAuthToken: "",
  themeID: "",
  latecomerVideoCode: "",
  addedBy: null
}

export default Vue.extend({
  components: {
    RtbRow,
    RtbCol,
    RtbButton,
    RtbLink,
    RtbCard,
    RtbCardBody,
    RtbCardActions,
    RtbTextInput,
    RtbSelect,
    RtbTabs,
    RtbTab,
    RtbTable,
    RtbSpinner,
    ThemesProvider,
    ThemeSelect,
    TagSelect
  },
  data() {
    return {
      tab: Tab.General,
      orgs: {},
      org: {
        ...orgEntity
      },
      viewing: false,
      items: [],
      deleting: false,
      editing: false,
      adding: false,
      query: "",
      headers: [
        {
          text: "Organization",
          value: "avatar",
          align: "left",
          sortable: false
        },
        {
          text: "Description",
          value: "description",
          align: "left",
          sortable: false
        },
        {
          text: "Action",
          value: "action",
          align: "left",
          sortable: false
        }
      ],
      twitchAccounts: [],
      pagination: {
        rowsPerPage: 20,
        page: 1
      },
      loading: false,
      themesTag: undefined
    }
  },
  async created() {
    await this.fetchOrgs()
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    allOrgs() {
      console.log("ORGS", this.orgs)
      return Object.entries(this.orgs).map(([orgID, org]) => ({
        ...org,
        theKey: orgID
      }))
    }
  },
  watch: {
    editing(value) {
      this.cancel(value)
      if (!value) {
        this.resetForm()
      }
    },
    viewing(to) {
      this.cancel(to)
    },
    deleting(to) {
      this.cancel(to)
    }
  },
  methods: {
    async fetchOrgs() {
      const orgs = await fetchOrgs()
      this.orgs = orgs || {}
    },
    async pushToGames(org) {
      await this.$store.dispatch("setOrgID", org.theKey)
      await this.$router.push("/games")
    },
    resetForm() {
      this.org = {
        ...orgEntity,
        latecomerVideoCode: DEFAULT_LATERCOMER_YOUTUBE_CODE
      }
      this.twitchAccounts = []
    },
    cancel(to) {
      if (to === false) {
        this.resetForm()
      }
    },
    view(org) {
      this.viewing = true
      this.org = { ...org }
    },
    closeModalDetail() {
      this.viewing = false
      this.org = null
    },
    openRemoveDialog(org) {
      this.org = { ...org }
      this.deleting = true
    },
    async remove() {
      await this.$store.dispatch("removeOrg", this.org)
      this.resetForm()
      this.deleting = false
      await this.fetchOrgs()
    },
    edit(org) {
      this.editing = true
      this.org = { ...org }
      this.twitchAccounts = Object.keys(org.twitchAccounts || {}).map(key => {
        return {
          key,
          ...org.twitchAccounts[key]
        }
      })
    },
    initOrgCreation() {
      this.editing = true
      this.adding = true
      this.resetForm()
    },
    async update() {
      this.loading = true
      if (this.adding) {
        this.org.addedBy = this.user.name
        await this.$store.dispatch("addOrg", this.org)
      } else {
        await this.$store.dispatch("updateOrg", {
          ...this.org,
          twitchAccounts: this.prepareTwitchAccounts()
        })
      }
      await this.fetchOrgs()
      this.editing = this.adding = false
      this.loading = false
    },
    async save() {
      this.org.avatar = "http://via.placeholder.com/480x200"
      this.org.id = this.items.length + 1
      console.log("time - " + this.org.time)
      this.items.push(this.org)
      this.resetForm()
      this.adding = false
      console.log("saving")
      await this.fetchOrgs()
    },
    prepareTwitchAccounts() {
      const obj = {}
      this.twitchAccounts.forEach(twitch => {
        const orgKey = this.org.theKey
        const key =
          twitch.key !== 0
            ? twitch.key
            : db.ref(`orgs/${orgKey}/twitchAccounts`).push().key
        obj[key] = {
          name: twitch.name || "",
          apiKey: twitch.apiKey || "",
          url: twitch.url || ""
        }
      })
      return obj
    },
    addTwitchAccount() {
      this.twitchAccounts.push({
        key: 0,
        name: "",
        apiKey: "",
        url: ""
      })
    },
    deleteTwitchAccount(index) {
      if (!confirm("You are deleting this twitch account")) return
      this.twitchAccounts = this.twitchAccounts.filter(
        (twitch, indx) => indx !== index
      )
    },
    getRules() {
      return rules
    }
  }
})
